const globalVars = {
  sandbox: 'false',
  ebayApiUrl: 'https://vtool-api.sw5-sandbox.com',
  swApiUrl: 'https://vtool-api.sw5-sandbox.com',
  ebayBusinesspolicyUrl: 'https://www.bizpolicy.ebay.de/businesspolicy/',
  ebayItemLink: 'http://cgi.sandbox.ebay.com/ws/eBayISAPI.dll?ViewItem&item=',
}

export { globalVars }

// ebayItemLink
// production = https://www.ebay.de/itm/
// sandbox = http://cgi.sandbox.ebay.com/ws/eBayISAPI.dll?ViewItem&item=
