<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Edit Profile
            </div>

            <div class="subtitle-1 font-weight-light">
              Complete your profile
            </div>
          </template>

          <!-- <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="apiurl"
                    label="Domain"
                    name="apiurl"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="apiuser"
                    class="purple-input"
                    label="API User Name"
                    name="apiuser"
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="apikey"
                    label="API Pass"
                    class="purple-input"
                    name="apikey"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="doLogin"
                  >
                    LOGIN
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form> -->

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Company (disabled)"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="apiuser"
                    class="purple-input"
                    label="Username"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Email Address"
                    class="purple-input"
                    :value="email"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="First Name"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Last Name"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Adress"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="City"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Country"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    class="purple-input"
                    label="Postal Code"
                    type="number"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="ebayauthtoken"
                    class="purple-input"
                    label="ebayauthtoken"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="ebayglobaltemplate"
                    class="purple-input"
                    label="eBay Template"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="savePaymentShippingReturnPolicy"
                  >
                    Alle Einstellungen speichern
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          :avatar="avatarLogo"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              CEO / CO-FOUNDER
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              Alec Thompson
            </h4>

            <p class="font-weight-light grey--text">
              Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
            </p>

            <v-btn
              color="success"
              rounded
              class="mr-0"
            >
              Follow
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Zahlungsmethoden, Versand & Rückgabe
            </div>

            <div class="subtitle-1 font-weight-light">
              Globale Einstellungen
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                >
                  <p>Payment Profiles ({{ paymentProfileRadios || 'null' }}) (gespeichert: {{ ebaypaymentid }})</p>
                  <v-container fluid>
                    <v-radio-group
                      v-model="paymentProfileRadios"
                      :mandatory="false"
                    >
                      <template
                        v-for="(l, i) in paymentProfile"
                        :value="l.profileId"
                      >
                        <v-radio
                          :key="i"
                          :value="l.profileId"
                          :label="l.profileName + ' (' + l.profileId + ')'"
                          class="black--text"
                        />
                        <i
                          :key="i + 'k'"
                          class="grey--text"
                          @click="openWindowEbayPaymentShippingReturnPolicy('payment', l.profileId)"
                        >Ansehen / Bearbeiten {{ ebayBusinesspolicyUrl }}payment?profileId={{ l.profileId }}</i>
                      </template>

                      <!-- <v-radio
                        v-for="(l, i) in paymentProfile"
                        :key="i"
                        :value="l.profileId"
                      >
                        <template slot="label">
                          {{ l.profileName }} ({{ l.profileId }})/n
                          <i>{{ l.profileDesc }}</i>
                        </template>
                        <template slot="label">
                          <i>xxxxx</i>
                        </template>
                      </v-radio> -->
                    </v-radio-group>
                  </v-container>
                </v-col>

                <v-col
                  cols="12"
                >
                  <p>Shipping Policy Profile ({{ shippingPolicyProfileRadios || 'null' }}) (gespeichert: {{ ebayshippingid }})</p>
                  <v-container fluid>
                    <v-radio-group
                      v-model="shippingPolicyProfileRadios"
                      :mandatory="false"
                    >
                      <template
                        v-for="(l, i) in shippingPolicyProfile"
                        :value="l.profileId"
                      >
                        <v-radio
                          :key="i"
                          :value="l.profileId"
                          :label="l.profileName + ' (' + l.profileId + ')'"
                          class="black--text"
                        />
                        <i
                          :key="i + 'k'"
                          class="grey--text"
                          @click="openWindowEbayPaymentShippingReturnPolicy('shipping', l.profileId)"
                        >Ansehen / Bearbeiten {{ ebayBusinesspolicyUrl }}payment?profileId={{ l.profileId }}</i>
                      </template>
                    </v-radio-group>
                  </v-container>
                </v-col>

                <v-col
                  cols="12"
                >
                  <p>Return Policy Profile ({{ returnPolicyProfileRadios || 'null' }}) (gespeichert: {{ ebayreturnid }})</p>
                  <v-container fluid>
                    <v-radio-group
                      v-model="returnPolicyProfileRadios"
                      :mandatory="false"
                    >
                      <template
                        v-for="(l, i) in returnPolicyProfile"
                        :value="l.profileId"
                      >
                        <v-radio
                          :key="i"
                          :value="l.profileId"
                          :label="l.profileName + ' (' + l.profileId + ')'"
                          class="black--text"
                        />
                        <i
                          :key="i + 'k'"
                          class="grey--text"
                          @click="openWindowEbayPaymentShippingReturnPolicy('return', l.profileId)"
                        >Ansehen / Bearbeiten {{ ebayBusinesspolicyUrl }}payment?profileId={{ l.profileId }}</i>
                      </template>
                    </v-radio-group>
                  </v-container>
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="savePaymentShippingReturnPolicy"
                  >
                    Alle Einstellungen speichern
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapMutations, mapState } from 'vuex'

  // getEbayUserPaymentShippingReturnPolicy.php
  // https://www.bizpolicy.ebay.de/businesspolicy/payment?pageNumber=1&profileId=45016029014&totalPages=1&source=manage
  // payment, shipping, return
  import { globalVars } from '@/globalVars.js'
  export default {

    mixins: [Proxyable],

    data: () => ({
      swApiUrl: globalVars.swApiUrl,
      ebayApiUrl: globalVars.ebayApiUrl,
      ebayBusinesspolicyUrl: globalVars.ebayBusinesspolicyUrl,
      apiurl: '',
      apiuser: '',
      apikey: '',
      sandbox: globalVars.sandbox,
      ebayauthtoken: '',
      ebayglobaltemplate: '',
      ebaypaymentid: '',
      ebayshippingid: '',
      ebayreturnid: '',
      email: '',
      apiQuery: {},
      userData: {},
      paymentProfileRadios: 'paymentProfileRadios',
      shippingPolicyProfileRadios: 'shippingPolicyProfileRadios',
      returnPolicyProfileRadios: 'returnPolicyProfileRadios',
      avatarLogo: '',
      item: 1,
      items: [
        { profileName: 'Real-Time', profileId: 'mdi-clock' },
        { profileName: 'Audience', profileId: 'mdi-account' },
        { profileName: 'Conversions', profileId: 'mdi-flag' },
      ],
      paymentProfile: [],
      returnPolicyProfile: [],
      shippingPolicyProfile: [],
    }),

    computed: {
      ...mapState(['barColor', 'barImage', 'loginStatus', 'swApiQuery']),
      swApiQuery: {
        get () {
          return this.$store.state.swApiQuery
        },
        set (val) {
          this.$store.commit('SET_SW_API_QUERY', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
    },

    created () {
      this.getApiData()
      // this.getUserProfile()
      // this.getEbayUserPaymentShippingReturnPolicy()
      this.setLoginStatus('TEST222')
    },

    methods: {

      ...mapMutations({
        setLoginStatus: 'SET_LOGIN_STATUS',
        setSwApiQuery: 'SET_SW_API_QUERY',
      }),

      openWindowEbayPaymentShippingReturnPolicy: function (policy, profileId) {
        window.open(this.ebayBusinesspolicyUrl + policy + '?profileId=' + profileId)
      },

      // doLogin () {
      //   // var date = new Date()
      //   // date.setDate(date.getDate() + 1)
      //   // this.$cookie.set('apiurl', this.apiurl, { expires: date })
      //   // this.$cookie.set('apiuser', this.apiuser, { expires: date })
      //   // this.$cookie.set('apikey', this.apikey, { expires: date })
      //   // this.getUserProfile()
      // },

      savePaymentShippingReturnPolicy () {
        console.log('savePaymentShippingReturnPolicy', this.paymentProfileRadios)
        this.$http.put(this.swApiUrl + '/sw-api/putUserProfile.php', JSON.stringify({
          ebayauthtoken: this.ebayauthtoken,
          ebayglobaltemplate: this.ebayglobaltemplate,
          paymentProfileRadios: this.paymentProfileRadios,
          shippingPolicyProfileRadios: this.shippingPolicyProfileRadios,
          returnPolicyProfileRadios: this.returnPolicyProfileRadios,
        })).then((resp) => {
          // console.log('22222apiQuery:', this.apiQuery)
          // console.log(JSON.stringify(resp.data.data))
          // this.shopArticles = resp.data.data
          console.log('save-postUserProfile.php:', resp.data)
        })
      },

      getApiData () {
        // const apiurl = this.$cookie.get('apiurl')
        // const apiuser = this.$cookie.get('apiuser')
        // const apikey = this.$cookie.get('apikey')
        // this.apiurl = apiurl
        // this.apiuser = apiuser
        // this.apikey = apikey
        // this.apiQuery = '{ "apiurl": "' + apiurl + '", "apiuser": "' + apiuser + '", "apikey": "' + apikey + '", "sandbox": "' + this.sandbox + '"}'
        this.getUserProfile()
        this.getShopLogo()
        this.getEbayUserPaymentShippingReturnPolicy()
      },

      getUserProfile () {
        this.$http.get(this.swApiUrl + '/sw-api/getUserProfile.php').then((resp) => {
          if (resp.data.success === true) {
            this.setLoginStatus(resp.data.data.username)
          } else {
            this.setLoginStatus('Bitte Login...')
          }
          // console.log(JSON.stringify(resp.data.data))
          this.userData = resp.data.data
          this.ebayauthtoken = this.userData.attribute.ebayauthtoken
          this.ebayglobaltemplate = this.userData.attribute.ebayglobaltemplate
          this.email = this.userData.email
          // Versand
          this.ebaypaymentid = this.userData.attribute.ebaypaymentid
          this.ebayshippingid = this.userData.attribute.ebayshippingid
          this.ebayreturnid = this.userData.attribute.ebayreturnid

          this.paymentProfileRadios = this.userData.attribute.ebaypaymentid
          this.shippingPolicyProfileRadios = this.userData.attribute.ebayshippingid
          this.returnPolicyProfileRadios = this.userData.attribute.ebayreturnid
          // console.log('apiQuery:getArticleList:', JSON.stringify(resp.data))
        })
      },

      getShopLogo () {
        this.$http.get(this.swApiUrl + '/sw-api/getShopLogo.php?shopUrl=' + this.$store.state.swApiQuery.apiurl).then((resp) => {
          this.avatarLogo = resp.data
        })
      },

      getEbayUserPaymentShippingReturnPolicy () {
        this.$http.get(this.ebayApiUrl + '/getEbayUserPaymentShippingReturnPolicy.php').then((resp) => {
          // console.log('apiQuery:', this.apiQuery, 'getArticleList:', JSON.stringify(resp.data))
          this.paymentProfile = resp.data.paymentProfile
          this.returnPolicyProfile = resp.data.returnPolicyProfile
          this.shippingPolicyProfile = resp.data.shippingPolicyProfile
        })
      },

      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>

<style scoped>
  .black--text /deep/ label {
      color: #2c2c2c;
  }
  .grey--text /deep/ label {
      color: #dbdada;
  }
</style>
